import { gql } from "@apollo/client";
import { PAYMENT_METHODS_FRAGMENT } from "./get-my-payment-methods.gql";
import { Image } from "~/types/Image";
import { ResourceType } from "~/types/ResourceType";

export type Organization = {
  id: string;
  name: string;
  logo?: Image;
  customResourceTypeNames: {
    [key in ResourceType]: string;
  };
};

export type UserAccountType = {
  email: string;
  roles: string[];
  id: string;
  email_verified: boolean;
  sub: string;
  organization: {
    id: string;
    name: string;
    customResourceTypeNames: {
      [key in ResourceType]: string;
    };
  };
  name: string;
  picture: string;
  identityProviderProfile: {
    firstName: string;
    lastName: string;
    postalCode: string | null;
  };
  profile: {
    firstName: string;
    lastName: string;
    id: string;
    termsOfService: {
      version: string;
      acceptedAt: string;
    };
    privacyPolicy: {
      version: string;
      acceptedAt: string;
    };
    postalCode?: string;
    countryIso?: string;
    isPrivacyMode?: boolean;
    profilePicture?: string;
  };
  segmentIds: string[];
};

export type GetMeResult = {
  me: UserAccountType;
  myPaymentMethod: any;
};

export const GET_ME_QUERY = gql`
  ${PAYMENT_METHODS_FRAGMENT}
  query Me {
    me {
      id
      email
      email_verified
      identities {
        provider
        user_id
      }
      name
      nickname
      organization {
        id
        name
        logo {
          url
          width
          height
        }
        customResourceTypeNames {
          RESOURCE_TYPE_HOT_DESK
          RESOURCE_TYPE_FIXED_DESK
          RESOURCE_TYPE_MEETING_ROOM
          RESOURCE_TYPE_LOUNGE
          RESOURCE_TYPE_PRIVATE_OFFICE
        }
      }
      picture
      identityProviderProfile {
        firstName
        lastName
        postalCode
      }
      profile {
        firstName
        lastName
        countryIso
        postalCode
        termsOfService {
          version
          acceptedAt
        }
        privacyPolicy {
          version
          acceptedAt
        }
        isPrivacyMode
        profilePicture
      }
      segmentIds
      roles
      sub
      updated_at
    }
    ...PaymentMethodsFragment
  }
`;
