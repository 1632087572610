import { gql } from '@apollo/client';

export enum PaymentMethodType {
  CARD = 'CARD',
  INVOICE = 'INVOICE',
}

export type PaymentMethod = {
  id: string;
  name: string;
  ownerId: string;
  paymentType: PaymentMethodType;
};

export const GET_MY_PAYMENT_METHODS_QUERY = gql`
  query MyPaymentMethods {
    myPaymentMethod {
      id
      name
      ownerId
      paymentType
    }
  }
`;

export const PAYMENT_METHODS_FRAGMENT = gql`
  fragment PaymentMethodsFragment on Query {
    myPaymentMethod {
      id
      name
      ownerId
      paymentType
    }
  }
`;
