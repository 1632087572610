"use client";

import {
  useGateValue,
  useDynamicConfig as useStatsigDynamicConfig,
  DynamicConfig,
} from "@statsig/react-bindings";

export type GateName =
  | "datadog-browser-logging-perm"
  | "share-booking-perm"
  | "credit-card"
  | "subscribable-resources"
  | "social-feature"
  | "calendar-integrations-perm"
  | "image-optimization"
  | "booking-overview-temp"
  | "quick-book-in-saved-temp"
  | "fast-search"
  | "recent-bookings-temp"
  | "bookmarks-carousel-temp"
  | "resource-popularity-sorting-perm";

export function useFeatureGate(gateName: GateName): boolean {
  return useGateValue(gateName);
}

export type DynamicConfigName =
  | "service-log-level"
  | "guest-datadog-config"
  | "dashboard-test-org-id";

export function useDynamicConfig(configName: DynamicConfigName): DynamicConfig {
  return useStatsigDynamicConfig(configName);
}
